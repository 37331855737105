import { containsOnlyEmojis } from '@slido/core';
import { debounce } from 'lodash-es';

import { createEntryHtmlElementSkeleton, WordcloudCanvasEntry } from './wordcloud-canvas-entry';
import {
  CLASS_NAME_CANVAS,
  CLASS_NAME_ENTRY_SHADOW,
  EMOJI_SCALE_FACTOR,
  HORIZONTAL_PADDING_LEVEL_MULTIPLIER,
  MIN_ALLOWED_FONT_SIZE,
  MIN_HORIZONTAL_PADDING,
  MIN_VERTICAL_PADDING,
  VERTICAL_PADDING_LEVEL_MULTIPLIER,
  WINDOW_RESIZE_DEBOUNCE_PERIOD,
} from './wordcloud-constants';
import { type EntryDrawData, type EntryMeasurements, Paddings } from './wordcloud-engine';
import {
  type WordcloudFontSizeOptions,
  type WordcloudPalette,
  type WordcloudVotesAriaLabelGetter,
} from './wordcloud-options';

/**
 * This class should be rather "dumb" and shouldn't know how to calculate the positions of entries. It only receives
 * positions and sizes which were already calculated (by the "engine") and applies these to real HTML elements.
 */
export class WordcloudCanvas {
  width: number;
  height: number;
  readonly entries = new Map<string, WordcloudCanvasEntry>();

  private canvasElement: HTMLElement;
  private shadowEntryElement: HTMLElement;
  private palette: WordcloudPalette;
  private fontSizeOptions: WordcloudFontSizeOptions;
  private levelsCount: number;
  private votesAriaLabelGetter: WordcloudVotesAriaLabelGetter;
  private canvasResizedDebounced: () => void;
  private emitCanvasResize: () => unknown;
  private resizeObserver: ResizeObserver;

  constructor({
    canvasElement,
    width,
    height,
    palette,
    fontSizeOptions,
    onCanvasResize,
    votesAriaLabelGetter,
  }: {
    canvasElement: HTMLElement;
    fontSizeOptions: WordcloudFontSizeOptions;
    height?: number;
    onCanvasResize: () => unknown;
    palette: WordcloudPalette;
    votesAriaLabelGetter: WordcloudVotesAriaLabelGetter;
    width?: number;
  }) {
    this.canvasElement = canvasElement;
    this.palette = palette;
    this.fontSizeOptions = fontSizeOptions;
    this.levelsCount = Object.keys(palette).length;
    this.emitCanvasResize = onCanvasResize;
    this.votesAriaLabelGetter = votesAriaLabelGetter;

    canvasElement.classList.add(CLASS_NAME_CANVAS);

    if (width != null) {
      canvasElement.style.width = `${width}px`;
    } else {
      width = canvasElement.offsetWidth;
    }

    if (height != null) {
      canvasElement.style.height = `${height}px`;
    } else {
      height = canvasElement.offsetHeight;
    }

    this.width = width;
    this.height = height;
    this.shadowEntryElement = this.createEntryHtmlElementShadow();
    canvasElement.appendChild(this.shadowEntryElement);

    this.canvasResizedDebounced = debounce(this.canvasResized, WINDOW_RESIZE_DEBOUNCE_PERIOD);

    window.addEventListener('resize', this.canvasResizedDebounced);
    this.resizeObserver = new ResizeObserver(this.canvasResizedDebounced);
    this.resizeObserver.observe(canvasElement);
  }

  /** In pixels */
  private get minFontSize() {
    const fontSize = this.fontSizeOptions.min * (this.fontSizeOptions.type === 'dynamic' ? this.width : 1);
    return Math.max(fontSize, MIN_ALLOWED_FONT_SIZE);
  }

  /** In pixels */
  private get maxFontSize() {
    return this.fontSizeOptions.max * (this.fontSizeOptions.type === 'dynamic' ? this.width : 1);
  }

  destroy(): void {
    this.resizeObserver.disconnect();
    window.removeEventListener('resize', this.canvasResizedDebounced);

    this.canvasElement.classList.remove(CLASS_NAME_CANVAS);

    this.clearCanvas();

    this.shadowEntryElement.parentNode?.removeChild(this.shadowEntryElement);
  }

  upsertEntry(drawData: EntryDrawData): void {
    let entry = this.entries.get(drawData.id);

    if (entry) {
      entry.update(drawData);
    } else {
      entry = new WordcloudCanvasEntry({
        canvasElement: this.canvasElement,
        drawData,
        palette: this.palette,
        votesAriaLabelGetter: this.votesAriaLabelGetter,
      });

      this.entries.set(drawData.id, entry);
    }
  }

  removeEntry(entryId: string): void {
    const entry = this.entries.get(entryId);

    if (entry) {
      entry.removeAnimated();
      this.entries.delete(entryId);
    }
  }

  clearCanvas(): void {
    for (const entry of this.entries.values()) {
      entry.destroy();
    }
    this.entries.clear();
  }

  measureEntry({ text, level, scaleFactor }: { level: number; scaleFactor: number; text: string }): EntryMeasurements {
    // Generate level sizes
    const fontSize = this.generateFontSizeForLevel(level, scaleFactor);
    const paddings = this.generatePaddingsForLevel(level, scaleFactor);

    // get reference of shadow HTML element which contains styles for text, so we can later transfer them to actual text element
    const contentElement = this.shadowEntryElement.lastElementChild as HTMLElement;
    const textElement = contentElement.firstElementChild as HTMLElement;

    this.shadowEntryElement.setAttribute(
      'style',
      [`font-size: ${fontSize}px;`, `padding: ${paddings.vertical}px ${paddings.horizontal}px;`].join(''),
    );
    textElement.innerHTML = text;

    // Calculate dimensions
    const ratio = containsOnlyEmojis(text) ? EMOJI_SCALE_FACTOR : 1;

    return {
      fontSize: fontSize * ratio,
      // 1 is added because browsers might internally have dimensions of textElement as float numbers
      // but they report it as rounded integers and the result might be a dimension which is a little bit smaller
      // than necessary and it might unnecessary wrap words afterwards
      height: Math.ceil(textElement.offsetHeight + paddings.vertical * 2 + 1) * ratio,
      paddings: paddings.scaleBy(ratio),
      width: Math.ceil(textElement.offsetWidth + paddings.horizontal * 2 + 1) * ratio,
    };
  }

  private canvasResized = () => {
    const { offsetWidth, offsetHeight } = this.canvasElement;

    if (this.width !== offsetWidth || this.height !== offsetHeight) {
      this.width = offsetWidth;
      this.height = offsetHeight;
      this.emitCanvasResize();
    }
  };

  private generateFontSizeForLevel(level: number, scaleFactor: number): number {
    const min = this.minFontSize;
    const max = Math.max(min + this.levelsCount - 1, this.maxFontSize * scaleFactor); // Max can not be less then min + levels - 1 => at least 1 px difference between each level
    const extraLevelRatio = (level - 1) / (this.levelsCount - 1);
    const totalExtraSize = max - min;
    const extraSize = totalExtraSize * extraLevelRatio;
    const size = Math.round(min + extraSize);
    return size;
  }

  private generatePaddingsForLevel(level: number, scaleFactor: number): Paddings {
    const vertical = Math.max(
      Math.round(level * VERTICAL_PADDING_LEVEL_MULTIPLIER * scaleFactor),
      MIN_VERTICAL_PADDING,
    );
    const horizontal = Math.max(
      Math.round(level * HORIZONTAL_PADDING_LEVEL_MULTIPLIER * scaleFactor),
      MIN_HORIZONTAL_PADDING,
    );
    return new Paddings(horizontal, vertical);
  }

  /** Creates HTML element for a "shadow" wordcloud entry (used for size measurements) */
  private createEntryHtmlElementShadow(): HTMLElement {
    // Create the HTML structure (we use the same HTML structure as for normal entries, to keep it as close to each other as possible)
    const { entryElement } = createEntryHtmlElementSkeleton(false);

    // Some stuff specific for the shadow HTML element
    entryElement.setAttribute('class', CLASS_NAME_ENTRY_SHADOW);
    entryElement.setAttribute('aria-hidden', 'true');

    return entryElement;
  }
}
