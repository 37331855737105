export const CLASS_NAME_CANVAS = 'jqcloud';
export const CLASS_NAME_ENTRY = 'jqcloud-word';
export const CLASS_NAME_ENTRY_SHADOW = 'jqcloud-word-shadow';
export const CLASS_NAME_ENTRY_BACKGROUND = 'jqcloud-word__background';
export const CLASS_NAME_ENTRY_CONTENT = 'jqcloud-word__content';
export const CLASS_NAME_ENTRY_TEXT = 'jqcloud-word__text';

export const WINDOW_RESIZE_DEBOUNCE_PERIOD = 200;

export const MIN_VERTICAL_PADDING = 6;
export const VERTICAL_PADDING_LEVEL_MULTIPLIER = 1.25;
export const MIN_HORIZONTAL_PADDING = 8;
export const HORIZONTAL_PADDING_LEVEL_MULTIPLIER = 2;
export const EMOJI_SCALE_FACTOR = 1.5;

/** Factor of the total canvas width */
export const MIN_SPACING_FACTOR = 0.005;

export const MIN_ALLOWED_FONT_SIZE = 10;
export const MAX_FONT_SIZE_DECREASE_ATTEMPTS = 5;
export const FONT_SIZE_ATTEMPT_DECREASE_RATIO = 0.87; // 5th attempt with this decrease ratio will result in cca 50% of initial size

export const PLACEMENT_RADIUS_STEP = (Math.PI * 2) / 5 + 0.06;
