import { DurationInspector } from '@slido/core';
import { isUndefined, omitBy } from 'lodash-es';

import { WordcloudCanvas } from './wordcloud-canvas';
import { type Entry, WordcloudEngine } from './wordcloud-engine';
import { type WordcloudEntry, type WordcloudOptions } from './wordcloud-options';

export class Wordcloud {
  private engine: WordcloudEngine;
  private canvas: WordcloudCanvas;
  private entries: Entry[];
  private levelsCount: number;
  private drawDelayTimeout?: number;
  private durationInspector: DurationInspector;

  constructor(
    canvasElement: HTMLElement,
    words: WordcloudEntry[],
    private options: WordcloudOptions = {},
  ) {
    // NOTICE: if you change these defaults then keep in mind that JSDoc for those options might need to be updated, too
    const optionsWithDefaults = {
      ariaLabel: '',
      center: { x: 0.5, y: 0.5 },
      fontSize: { max: 0.09, min: 0.02, type: 'dynamic' as const },
      palette: defaultPalette,
      removeOverflowing: true,
      votesAriaLabelGetter: () => '',
      ...(omitBy(options, isUndefined) as WordcloudOptions),
    };

    this.levelsCount = Object.keys(optionsWithDefaults.palette).length;

    canvasElement.setAttribute('role', 'list');
    canvasElement.setAttribute('aria-label', optionsWithDefaults.ariaLabel);
    canvasElement.setAttribute('tabindex', '-1');

    this.canvas = new WordcloudCanvas({
      canvasElement,
      fontSizeOptions: optionsWithDefaults.fontSize,
      height: optionsWithDefaults.height,
      onCanvasResize: this.canvasResized,
      palette: optionsWithDefaults.palette,
      votesAriaLabelGetter: optionsWithDefaults.votesAriaLabelGetter,
      width: optionsWithDefaults.width,
    });

    this.engine = new WordcloudEngine({
      availableHeight: this.canvas.height,
      availableWidth: this.canvas.width,
      measureEntry: this.canvas.measureEntry.bind(this.canvas),
    });

    this.entries = this.mapWordsToEntries(words);

    this.drawDelayTimeout = window.setTimeout(this.draw.bind(this), 10);

    this.durationInspector = new DurationInspector({
      log: { prefix: 'wordcloud.draw' },
    });
  }

  destroy(): void {
    this.canvas.destroy();
    this.clearDrawDelayTimeout();
  }

  update(words: WordcloudEntry[]): void {
    const entries = this.mapWordsToEntries(words);

    if (!areEntriesEqual(entries, this.entries)) {
      this.entries = entries;
      this.draw();
    }
  }

  private redraw(forceRandomPosition = false): void {
    this.clearDrawDelayTimeout();
    this.draw(forceRandomPosition);
  }

  private draw(forceRandomPosition = false): void {
    if (this.options.onDurationMeasured) {
      this.durationInspector.iterationStart();
    }

    const drawData = this.engine.calculateDrawData(this.entries, forceRandomPosition);

    // Remove non-positioned from DOM
    for (const id of this.canvas.entries.keys()) {
      const entryDrawData = drawData.entries.find((entryDrawData) => entryDrawData.id === id);

      if (!entryDrawData || entryDrawData.teleport) {
        this.canvas.removeEntry(id);
      }
    }

    // Now that positions and sizes are calculated, we can proceed to actually setting and animating them
    for (const entryDrawData of drawData.entries) {
      this.canvas.upsertEntry(entryDrawData);
    }

    if (this.options.onDurationMeasured) {
      const duration = this.durationInspector.iterationEnd();
      this.options.onDurationMeasured(duration);
    }
  }

  private canvasResized = () => {
    this.engine.dimensionsUpdated(this.canvas.width, this.canvas.height);
    this.redraw(true);
  };

  private clearDrawDelayTimeout() {
    if (this.drawDelayTimeout !== undefined) {
      window.clearTimeout(this.drawDelayTimeout);
      this.drawDelayTimeout = undefined;
    }
  }

  private mapWordsToEntries(words: WordcloudEntry[]): Entry[] {
    if (words.length === 0) {
      return [];
    }

    const defaultLevel = Math.floor(this.levelsCount / 2); // Default level is cca in the middle (it's used in case all the items have the same weight)

    // Sort words by weight (descending), create ids for each word and detect min and max used weight
    const wordHashInstanceCounter = new Map<string, number>();
    const sortedWords = words.filter((word) => word != null).sort((a, b) => (b.weight ?? 0) - (a.weight ?? 0));

    const minWeight = sortedWords[sortedWords.length - 1].weight ?? 0;
    const maxWeight = sortedWords[0].weight ?? 0;

    // Enrich entries -> calculate proper level and reuse existing entry if possible
    const maxExtraWeight = maxWeight - minWeight;
    const maxExtraLevel = this.levelsCount - 1;

    return sortedWords.map((word): Entry => {
      let level = defaultLevel;

      if (maxExtraLevel && maxExtraWeight) {
        // If there are some differences in weights, then linearly map the original weight to a discrete scale from 1 to the number of levels
        const extraWeight = word.weight - minWeight;
        const extraWeightRatio = extraWeight / maxExtraWeight;
        const extraLevel = Math.round(extraWeightRatio * maxExtraLevel);
        level = 1 + extraLevel;
      }

      return {
        id: this.computeEntryId(word, wordHashInstanceCounter),
        level,
        text: word.text,
        votesCount: word.votesCount,
      };
    });
  }

  private computeWordHash(text: string): string {
    return btoa(encodeURIComponent(text));
  }

  private computeEntryId(word: WordcloudEntry, wordHashInstanceCounter: Map<string, number>): string {
    const hash = this.computeWordHash(word.text);
    const instance = wordHashInstanceCounter.get(hash);
    if (instance != null && instance > 0) {
      wordHashInstanceCounter.set(hash, instance + 1);
      return `${hash}___${instance}`;
    }
    wordHashInstanceCounter.set(hash, 1);

    return hash;
  }
}

/** This algorithm is relying on the fact that there are no two entries with the same text */
function areEntriesEqual(a: Entry[], b: Entry[]): boolean {
  if (a.length !== b.length) {
    return false;
  }

  const mapA = new Map<string, Entry>();

  // Transform the first array into a map, for faster access
  for (const item of a) {
    mapA.set(item.text, item);
  }

  for (const itemB of b) {
    const itemA = mapA.get(itemB.text);
    if (!itemA || itemA.level !== itemB.level) {
      return false;
    }
  }

  return true;
}

const defaultPalette = {
  1: {
    background: 'rgba(64, 64, 64, 0.1)',
    text: 'rgb(64, 64, 64)',
  },
  2: {
    background: 'rgba(74, 118, 143, 0.1)',
    text: 'rgb(74, 118, 143)',
  },
  3: {
    background: 'rgba(54, 170, 185, 0.1)',
    text: 'rgb(54, 170, 185)',
  },
  4: {
    background: 'rgba(3, 155, 229, 0.1)',
    text: 'rgb(3, 155, 229)',
  },
  5: {
    background: 'rgba(0, 150, 136, 0.1)',
    text: 'rgb(0, 150, 136)',
  },
  6: {
    background: 'rgba(21, 101, 192, 0.1)',
    text: 'rgb(21, 101, 192)',
  },
  7: {
    background: 'rgba(18, 43, 95, 0.1)',
    text: 'rgb(18, 43, 95)',
  },
};
