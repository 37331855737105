import {
  CLASS_NAME_ENTRY,
  CLASS_NAME_ENTRY_BACKGROUND,
  CLASS_NAME_ENTRY_CONTENT,
  CLASS_NAME_ENTRY_TEXT,
} from './wordcloud-constants';
import { type EntryDrawData, Paddings, type Rectangle } from './wordcloud-engine';
import { type WordcloudPalette, type WordcloudVotesAriaLabelGetter } from './wordcloud-options';

// Structure of entry element is like this:
// - entryElement - div
//   - backgroundElement - div
//   - contentElement - div
//     - textElement - span - visible to user, hidden from screen-reader software
//     - ariaTextElement - div - hidden from user, visible to screen-reader software
export class WordcloudCanvasEntry {
  private rootElement: HTMLElement;
  private backgroundElement: HTMLElement;
  private contentElement: HTMLElement;
  private ariaTextElement: HTMLElement;
  private latestDrawData: EntryDrawData;
  private palette: WordcloudPalette;
  private votesAriaLabelGetter: WordcloudVotesAriaLabelGetter;
  private rafHandle?: number;

  constructor({
    canvasElement,
    drawData,
    votesAriaLabelGetter,
    palette,
  }: {
    canvasElement: HTMLElement;
    drawData: EntryDrawData;
    palette: WordcloudPalette;
    votesAriaLabelGetter: (votes: number) => string;
  }) {
    this.palette = palette;
    this.votesAriaLabelGetter = votesAriaLabelGetter;
    this.latestDrawData = drawData;

    // Create the HTML structure
    const { ariaTextElement, backgroundElement, contentElement, entryElement, textElement } =
      createEntryHtmlElementSkeleton(true);

    this.rootElement = entryElement;
    this.backgroundElement = backgroundElement;
    this.contentElement = contentElement;
    this.ariaTextElement = ariaTextElement as HTMLDivElement;

    // Some stuff specific for the visible HTML element
    this.rootElement.setAttribute('id', drawData.id);
    this.rootElement.setAttribute('role', 'listitem');
    textElement.setAttribute('aria-hidden', 'true');
    textElement.innerText = drawData.text;

    this.setColorsAndAriaLabel(drawData.level, drawData.votes);

    canvasElement.appendChild(this.rootElement);

    this.animatePositionAndSize(drawData, true);
  }

  destroy(): void {
    this.rootElement.parentElement?.removeChild(this.rootElement);
  }

  update(drawData: EntryDrawData): void {
    if (drawData.level !== this.latestDrawData.level) {
      this.setColorsAndAriaLabel(drawData.level, drawData.votes);
    }

    if (this.isDifferentPositionOrSize(drawData)) {
      this.animatePositionAndSize(drawData, false);
    }

    this.latestDrawData = drawData;
  }

  removeAnimated(): void {
    const rectangle = this.latestDrawData.rectangle;

    this.setPositionAndSize(
      {
        height: 1,
        left: rectangle.left + rectangle.width / 2,
        top: rectangle.top + rectangle.height / 2,
        width: 1,
      },
      1,
      new Paddings(0, 0),
      0,
    );

    window.setTimeout(() => {
      this.destroy();
    }, 500);
  }

  private setColorsAndAriaLabel(level: number, votes: number) {
    this.backgroundElement.style.setProperty('background', this.palette[level].background);
    this.contentElement.style.setProperty('color', this.palette[level].text);
    const levelAriaLabel = this.votesAriaLabelGetter(votes);
    this.ariaTextElement.innerText = levelAriaLabel
      ? `${this.latestDrawData.text}, ${levelAriaLabel}`
      : this.latestDrawData.text;
  }

  private animatePositionAndSize(drawData: EntryDrawData, initialAnimation: boolean): void {
    if (initialAnimation) {
      this.setPositionAndSize(
        {
          height: 1,
          left: drawData.rectangle.left + drawData.rectangle.width / 2,
          top: drawData.rectangle.top + drawData.rectangle.height / 2,
          width: 1,
        },
        1,
        new Paddings(0, 0),
        0,
      );

      // For some reason we need to request animation frame twice, otherwise the animation does not look ok
      this.rafHandle = requestAnimationFrame(() => {
        this.rafHandle = requestAnimationFrame(() => {
          this.rafHandle = undefined;
          this.setPositionAndSize(drawData.rectangle, drawData.fontSize, drawData.paddings);
        });
      });
    } else {
      this.setPositionAndSize(drawData.rectangle, drawData.fontSize, drawData.paddings);
    }
  }

  private setPositionAndSize(rectangle: Rectangle, fontSize: number, paddings: Paddings, opacity = 1): void {
    if (this.rafHandle != null) {
      // `requestAnimationFrame` doesn't have to always resolve quickly, for example when tab is not focused.
      // This cancellation is required to avoid race conditions.
      cancelAnimationFrame(this.rafHandle);
    }

    const { top, left, width, height } = rectangle;
    const { horizontal: horizontalPadding, vertical: verticalPadding } = paddings;

    this.rootElement.setAttribute(
      'style',
      [
        `left: ${left}px;`,
        `top: ${top}px;`,
        `width: ${width}px;`,
        `height: ${height}px;`,
        `font-size: ${fontSize}px;`,
        `padding: ${verticalPadding}px ${horizontalPadding}px;`,
        `opacity: ${opacity};`,
      ].join(''),
    );
  }

  private isDifferentPositionOrSize(newDrawData: EntryDrawData): boolean {
    return (
      this.latestDrawData.rectangle.top !== newDrawData.rectangle.top ||
      this.latestDrawData.rectangle.left !== newDrawData.rectangle.left ||
      this.latestDrawData.rectangle.width !== newDrawData.rectangle.width ||
      this.latestDrawData.rectangle.height !== newDrawData.rectangle.height ||
      this.latestDrawData.paddings.horizontal !== newDrawData.paddings.horizontal ||
      this.latestDrawData.paddings.vertical !== newDrawData.paddings.vertical ||
      this.latestDrawData.fontSize !== newDrawData.fontSize
    );
  }
}

export function createEntryHtmlElementSkeleton(withAria: boolean): {
  ariaTextElement?: HTMLElement;
  backgroundElement: HTMLElement;
  contentElement: HTMLElement;
  entryElement: HTMLElement;
  textElement: HTMLElement;
} {
  const entryElement = document.createElement('div');
  entryElement.setAttribute('class', CLASS_NAME_ENTRY);
  entryElement.setAttribute('tabindex', '-1');

  const backgroundElement = document.createElement('div');
  backgroundElement.setAttribute('class', `${CLASS_NAME_ENTRY_BACKGROUND}`);
  entryElement.appendChild(backgroundElement);

  const contentElement = document.createElement('div');
  contentElement.setAttribute('class', `${CLASS_NAME_ENTRY_CONTENT}`);
  entryElement.appendChild(contentElement);

  const textElement = document.createElement('span');
  textElement.setAttribute('class', CLASS_NAME_ENTRY_TEXT);
  contentElement.appendChild(textElement);

  let ariaTextElement: HTMLDivElement | undefined;

  if (withAria) {
    ariaTextElement = document.createElement('div');
    ariaTextElement.setAttribute(
      'style',
      'position: absolute; top: 0; left: 0; width: 0; height: 0; overflow: hidden;',
    );
    contentElement.appendChild(ariaTextElement);
  }

  return { ariaTextElement, backgroundElement, contentElement, entryElement, textElement };
}
