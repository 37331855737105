import {
  FONT_SIZE_ATTEMPT_DECREASE_RATIO,
  MAX_FONT_SIZE_DECREASE_ATTEMPTS,
  MIN_SPACING_FACTOR,
  PLACEMENT_RADIUS_STEP,
} from './wordcloud-constants';

/**
 * This class only deals with mathematics, it does not draw anything. Given constraints like the canvas size and an array of entries,
 * it calculates where the entries should be located and in what sizes.
 */
export class WordcloudEngine {
  private availableWidth: number;
  private availableHeight: number;
  private measureEntry: MeasureEntryFn;
  private removeOverflowing: boolean;
  private placementOptions = {
    angle: Math.random() * Math.PI * 2, // Angle is fixed for the full life-time of wordcloud instance
    radiusStep: PLACEMENT_RADIUS_STEP,
    // Visual illustration of the placing algorithm with these parameter (and comparison with the old settings):
    // https://jsfiddle.net/clockwise_dwarf/r05kq9og/13/
  };
  private previousDrawData: EntryDrawData[] | null = null;
  private cache = new Map<string, EntryMeasurements>();
  private iterationsCounter = 0;

  constructor(params: {
    availableHeight: number;
    availableWidth: number;
    measureEntry: MeasureEntryFn;
    removeOverflowing?: boolean;
  }) {
    this.availableWidth = params.availableWidth;
    this.availableHeight = params.availableHeight;
    this.measureEntry = params.measureEntry;
    this.removeOverflowing = params.removeOverflowing ?? true;
  }

  private get minSpacing() {
    return this.availableWidth * MIN_SPACING_FACTOR;
  }

  calculateDrawData(entries: Entry[], forceRandom = false): DrawData {
    let result: EntryDrawData[] = [];
    let sizeDecreaseAttempt = 0;
    let scaleFactor = 1;
    const newCache = new Map<string, EntryMeasurements>();
    this.iterationsCounter = 0;

    // This loop finds appropriate positions and sizes of wordcloud entries
    for (; sizeDecreaseAttempt <= MAX_FONT_SIZE_DECREASE_ATTEMPTS; sizeDecreaseAttempt++) {
      result = [];
      scaleFactor = FONT_SIZE_ATTEMPT_DECREASE_RATIO ** sizeDecreaseAttempt;
      const isLastSizeDecreaseAttempt = sizeDecreaseAttempt === MAX_FONT_SIZE_DECREASE_ATTEMPTS;

      // Find appropriate positions for entries
      const occupiedRectangles: Rectangle[] = [];
      let fits = true;

      for (let index = 0; index < entries.length; index++) {
        const entry = entries[index];

        const entryMeasurements = this.measureEntryCached(
          { level: entry.level, scaleFactor, text: entry.text },
          newCache,
        );

        let position: Point | null = null;
        const previousRectangle = this.previousDrawData?.find((e) => e.id === entry.id)?.rectangle;

        if (previousRectangle && !forceRandom) {
          // If the entry is already rendered somewhere, try finding a position not too far from the current one
          position = this.findAdjustedPositionForEntry(previousRectangle, entryMeasurements, occupiedRectangles);
        }

        if (!position) {
          // If entry is not positioned anywhere or we were not successful finding a position in the previous step,
          // try finding a random one
          position = this.findRandomPositionForEntry(
            { height: entryMeasurements.height, width: entryMeasurements.width },
            occupiedRectangles,
            index,
          );
        }

        if (position) {
          const rectangle: Rectangle = {
            ...position,
            height: entryMeasurements.height,
            width: entryMeasurements.width,
          };

          occupiedRectangles.push(rectangle);

          const travelDistance = previousRectangle
            ? getDistanceBetweenPoints(getCenter(rectangle), getCenter(previousRectangle))
            : null;

          result.push({
            fontSize: entryMeasurements.fontSize,
            id: entry.id,
            level: entry.level,
            paddings: entryMeasurements.paddings,
            rectangle,
            teleport: travelDistance != null && travelDistance > rectangle.height * 8,
            text: entry.text,
            votes: entry.votesCount,
          });
        } else if (!isLastSizeDecreaseAttempt) {
          // Do not return on last decrease attempt even if a word does not fit, just draw whatever can be drawn
          // if true is returned (word did not fit), then this 'some' iteration is cancelled and font sizes will be lowered
          fits = false;
          break;
        }
      }

      if (fits) {
        // All entries drawn => break the loop;
        break;
      }
    }

    // Preserve these results for the next iteration
    this.previousDrawData = result;

    // Rotate cache to prevent it from growing too large
    this.cache = newCache;

    // Log that size had to be decreased (if it was)
    const percentage = Math.round(scaleFactor * 1000) / 10;
    const dropPercentage = Math.round((1 - result.length / entries.length) * 10000) / 100;
    console.debug(
      `wordcloud: ${this.iterationsCounter} iterations; scale ${percentage}%; drop rate ${dropPercentage}%`,
    );

    return {
      entries: result,
    };
  }

  dimensionsUpdated(availableWidth: number, availableHeight: number): void {
    this.availableWidth = availableWidth;
    this.availableHeight = availableHeight;
  }

  private findRandomPositionForEntry(size: Size, occupiedRectangles: Rectangle[], index: number): Point | null {
    // Calculate initial position
    const initialPosition: Point = {
      left: this.availableWidth / 2 - size.width / 2,
      top: this.availableHeight / 2 - size.height / 2,
    };

    const rectangle = {
      ...initialPosition,
      ...size,
    };

    // Find the free position
    const radiusStep = this.placementOptions.radiusStep;
    const angleStep = (index % 2 === 0 ? 1 : -1) * radiusStep;
    let radius = 0;
    let angle = this.placementOptions.angle;

    while (hitTest(rectangle, occupiedRectangles, this.minSpacing)) {
      radius += radiusStep;
      angle += angleStep;
      this.iterationsCounter++;

      rectangle.left = Math.round(
        initialPosition.left + (radius * Math.cos(angle) * this.availableWidth) / this.availableHeight,
      );

      rectangle.top = Math.round(initialPosition.top + radius * Math.sin(angle));
    }

    // Don't render word if the position is not valid
    return this.isPositionValid(rectangle) ? { left: rectangle.left, top: rectangle.top } : null;
  }

  private findAdjustedPositionForEntry(
    previousRectangle: Rectangle,
    size: Size,
    occupiedRectangles: Rectangle[],
  ): Point | null {
    // Start from the middle
    const canvasCenter: Point = {
      left: this.availableWidth / 2,
      top: this.availableHeight / 2,
    };

    const previousCenter: Point = {
      left: previousRectangle.left + previousRectangle.width / 2,
      top: previousRectangle.top + previousRectangle.height / 2,
    };

    const position: Point = { ...canvasCenter };
    let rectangle: Rectangle = { ...position, ...size };

    const angleRad = getAngleBetweenPoints(canvasCenter, previousCenter) ?? getRandomAngle();
    let perpendicularFactor = 0;
    let found = false;

    do {
      let distance = 0;
      let perpendicularDirectionFactor = 1; // This is always 1 or -1

      do {
        this.iterationsCounter++;

        // First move towards the old position
        position.left = canvasCenter.left + Math.cos(angleRad) * distance;
        position.top = canvasCenter.top + Math.sin(angleRad) * distance;

        // ... then move perpendicular to that by some factor (which gradually increments)
        if (perpendicularFactor !== 0) {
          const perpendicularDistance = perpendicularFactor * distance * perpendicularDirectionFactor;
          position.left = position.left + Math.cos(angleRad - Math.PI / 2) * perpendicularDistance;
          position.top = position.top + Math.sin(angleRad - Math.PI / 2) * perpendicularDistance;
        }

        // ... and finally get rid of decimal points
        position.left = Math.round(position.left);
        position.top = Math.round(position.top);

        rectangle = moveRectangle(rectangle, position);

        if (!hitTest(rectangle, occupiedRectangles, this.minSpacing)) {
          // We found a suitable position, great!
          found = true;
          break;
        }

        if (perpendicularFactor !== 0 && perpendicularDirectionFactor === 1) {
          // If we're already trying to look perpendicularly, we must try both directions before increasing the distance
        } else {
          distance += 5;
        }
        perpendicularDirectionFactor += -1;
      } while (!this.isOverflowing(rectangle));

      perpendicularFactor += 0.1;
    } while (!found && perpendicularFactor < 0.7);

    // Don't render word if the position is not valid
    return this.isPositionValid(rectangle) ? { left: rectangle.left, top: rectangle.top } : null;
  }

  private isPositionValid(rectangle: Rectangle): boolean {
    return !this.removeOverflowing || !this.isOverflowing(rectangle);
  }

  private isOverflowing(rectangle: Rectangle): boolean {
    return (
      rectangle.left < 0 ||
      rectangle.top < 0 ||
      rectangle.left + rectangle.width > this.availableWidth ||
      rectangle.top + rectangle.height > this.availableHeight
    );
  }

  private measureEntryCached(
    { text, level, scaleFactor }: { level: number; scaleFactor: number; text: string },
    newCache: Map<string, EntryMeasurements>,
  ): EntryMeasurements {
    const key = getCacheKey(level, scaleFactor, text);
    const value = this.cache.get(key) ?? this.measureEntry({ level, scaleFactor, text });
    newCache.set(key, value);
    return value;
  }
}

function hitTest(rectangle: Rectangle, occupiedRectangles: Rectangle[], minSpacing = 0): boolean {
  // Check elements for overlap one by one, stop and return false as soon as an overlap is found
  return occupiedRectangles.some((placedEntry) => overlapping(rectangle, placedEntry, minSpacing));
}

function overlapping(a: Rectangle, b: Rectangle, minSpacing = 0): boolean {
  // Add padding of `minSpacing` around the first rectangle
  a = {
    height: a.height + 2 * minSpacing,
    left: a.left - minSpacing,
    top: a.top - minSpacing,
    width: a.width + 2 * minSpacing,
  };

  if (Math.abs(2 * a.left + a.width - 2 * b.left - b.width) < a.width + b.width) {
    if (Math.abs(2 * a.top + a.height - 2 * b.top - b.height) < a.height + b.height) {
      return true;
    }
  }
  return false;
}

/** Returns an angle in radians in range 0-2π or `undefined` when points are identical */
function getAngleBetweenPoints(p1: Point, p2: Point): number | undefined {
  if (p1.left === p2.left && p1.top === p2.top) {
    return undefined;
  }

  let rad = Math.atan((p2.top - p1.top) / (p2.left - p1.left));

  if (p2.left < p1.left) {
    rad += Math.PI;
  }

  return (rad + 2 * Math.PI) % (2 * Math.PI);
}

function getRandomAngle(): number {
  return Math.random() * 2 * Math.PI;
}

function getDistanceBetweenPoints(p1: Point, p2: Point): number {
  return Math.sqrt((p2.top - p1.top) ** 2 + (p2.left - p1.left) ** 2);
}

function getCenter(rectangle: Rectangle): Point {
  return {
    left: rectangle.left + rectangle.width / 2,
    top: rectangle.top + rectangle.height / 2,
  };
}

function moveRectangle(rectangle: Rectangle, position: Point): Rectangle {
  return {
    height: rectangle.height,
    left: position.left - rectangle.width / 2,
    top: position.top - rectangle.height / 2,
    width: rectangle.width,
  };
}

function getCacheKey(...parameters: Array<number | string>): string {
  return parameters.join(':');
}

export interface Point {
  left: number;
  top: number;
}

export interface Size {
  height: number;
  width: number;
}

export interface Rectangle extends Point, Size {}

export interface Entry {
  id: string;
  level: number;
  text: string;
  votesCount: number;
}

export class Paddings {
  horizontal: number;
  vertical: number;

  constructor(horizontal: number, vertical: number) {
    this.horizontal = horizontal;
    this.vertical = vertical;
  }

  scaleBy: (ratio: number) => Paddings = (ratio) => new Paddings(this.horizontal * ratio, this.vertical * ratio);
}

export type MeasureEntryFn = (params: { level: number; scaleFactor: number; text: string }) => EntryMeasurements;

export interface EntryMeasurements extends Size {
  fontSize: number;
  paddings: Paddings;
}

export interface DrawData {
  entries: EntryDrawData[];
}

export interface EntryDrawData {
  fontSize: number;
  id: string;
  level: number;
  paddings: Paddings;
  rectangle: Rectangle;
  /** Teleport = position is changing significantly, so rather than sliding it should teleport */
  teleport: boolean;
  text: string;
  votes: number;
}
